<template>
  <v-row class="ma-0">
    <v-col cols="2">
      <v-btn @click="prev" color="primary" dark fab small v-if="!isfirstStep">
        <v-icon dark>far fa-angle-left</v-icon>
      </v-btn>
    </v-col>
    <v-col cols="8">
      <v-menu offset-y>
        <template #activator="{ on }">
          <v-btn
              block
              color="accent"
              dark
              v-on="on"
          >
            <v-window v-model="step">
              <v-window-item :value="index" v-for="(item, index) in items" :key="index">
                {{ item.title }}
              </v-window-item>
            </v-window>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
              :key="index"
              @click="step = index"
              v-for="(item, index) in items"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
    <v-col cols="2">
      <v-btn @click="next" color="primary" dark fab small v-if="!isLastStep">
        <v-icon dark>far fa-angle-right</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'usage-unit-selector',
  watch: {
    step() {
      this.$emit('input', this.items[this.step].unit);
    },
  },
  data: () => ({
    step: 1,
    items: [
      {
        title: 'dag',
        unit: 'day',
      },
      {
        title: 'week',
        unit: 'week',
      },
      {
        title: 'maand',
        unit: 'month',
      },
      {
        title: 'jaar',
        unit: 'year',
      },
      {
        title: 'periode',
        unit: 'period',
      },
    ],
  }),
  computed: {
    isLastStep() {
      return this.step === (this.items.length - 1);
    },
    isfirstStep() {
      return this.step === 0;
    },
  },
  methods: {
    next() {
      this.step++;
    },
    prev() {
      this.step--;
    },
  },
};
</script>

<style scoped>

</style>
