import API from './../../API.js';

export default async function getStatistics(type, meters, from, to) {
  const result = await API.get(`me/organisation/current/measurement/chart/${type}/statistics`, {
    params: {
      meters,
      from,
      to,
    },
  });
  return result.data.data;
}
