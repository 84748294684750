<script>
import { isMobileOnly } from 'mobile-device-detect';
import { Line } from 'vue-chartjs';

export default {
  name: 'Usage',
  extends: Line,
  props: {
    electricity: {
      type: Boolean,
      default: false,
    },
    gas: {
      type: Boolean,
      default: false,
    },
    meters: {
      type: Array,
      required: true,
    },
    from: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      required: true,
    },
    unit: {
      type: String,
      required: true,
    },
  },
  created() {
    this.init();
  },
  computed: {
    getMeterGas() {
      return this.getMeters(1);
    },
    getMeterElectricity() {
      return this.getMeters(2);
    },
    isSmallScreen() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    displayFormats() {
      return {
        'minute': 'LT',
        'hour': 'D MMM',
        'day': 'D MMM',
        'month': 'MMM',
      };
    },
    unitText() {
      switch (this.unit) {
        default:
        case 'minute':
          return 'kwartier';

        case 'hour':
          return 'uur';

        case 'day':
          return 'dag';

        case 'month':
          return 'maand';

      }
    },
  },
  methods: {
    getColorByIndex(isGasType, colorIndex = 0) {
      if (colorIndex % 5 === 0) {
        return isGasType ? '#ecab20' : '#009cb4';
      }

      let colors = {
        1: '#20494f',
        2: '#00b569',
        3: '#be3030',
        4: '#e8177b',
      };
      return colors[colorIndex % 6];
    },
    convertHex(hex, opacity) {
      hex = hex.replace('#', '');
      let r = parseInt(hex.substring(0, 2), 16);
      let g = parseInt(hex.substring(2, 4), 16);
      let b = parseInt(hex.substring(4, 6), 16);

      return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity / 100 + ')';
    },

    getMeters(type = 1) {
      return this.meters.reduce(function (result, meter) {
        if (meter.meter_type_id === type) {
          result.push(meter);
        }
        return result;
      }, []);
    },
    init() {
      this.$emit('loading');
      this.getChartData().then((data) => {
        this.$emit('getDates', data.dates);
        this.renderChart(data, {
          title: {
            display: false,
          },
          legend: {
            display: false,
          },
          responsive: true,
          lineTension: 1,
          scales: {
            yAxes: [{
              display: true,
              ticks: {
                beginAtZero: true,
              },
            }],
            xAxes: [{
              type: 'time',
              distribution: 'linear',
              time: {
                unit: this.unit,
                displayFormats: this.displayFormats,
              },
              ticks: {
                autoSkip: true,
                minRotation: 45,
                maxTicksLimit: this.isSmallScreen ? 5 : 15,
                min: this.from,
                max: this.to,
              },
              gridLines: {
                display: true,
              },
            }],
          },
          tooltips: {
            enabled: !isMobileOnly,
            callbacks: {
              title: (tooltipItem, data) => {
                let format = (this.unit === 'month' || this.unit === 'day') ? 'D MMMM YYYY' : 'LL LT';
                return this.$moment(data.datasets[tooltipItem[0].datasetIndex].data[tooltipItem[0].index].t, 'YYYY-MM-DD H:m:s')
                    .format(format);
              },
              label: (tooltipItem, data) => {
                let type = data.datasets[tooltipItem.datasetIndex]?.isElectricity ? 'kWh' : 'm3';
                let timeUnit = this.unitText;
                // gas is always in hours
                if (!data.datasets[tooltipItem.datasetIndex]?.isElectricity && timeUnit === 'kwartier') {
                  timeUnit = 'uur';
                }
                tooltipItem.value = parseFloat(tooltipItem.value);
                return `${data.datasets[tooltipItem.datasetIndex].label}: ${tooltipItem.value.toLocaleString('nl-NL')} ${type} per ${timeUnit}`;
              },
            },
            mode: 'index',
            intersect: false,
          },
          hover: {
            mode: 'index',
            intersect: false,
          },
          maintainAspectRatio: false,
          elements: {
            line: {
              tension: 0.2,
            },
          },
        });
        this.$emit('complete');
      });
    },
    // this one is called by the gas or electricity chart
    getChartData() {
      return new Promise((resolve) => {
        let promises = [];

        // Reset colors indexes for returning correct color
        let gasColorIndex = 0;
        let electricityColorIndex = 0;

        if (this.gas && this.getMeterGas.length) {
          //get specific GAS meters (from the prop) with a line for each meter
          this.getMeterGas.map(meter => {
            promises.push(this.getGasChartData(meter, gasColorIndex));
            gasColorIndex++;
          });
        } else if (this.electricity && this.getMeterElectricity.length) {
          //get specific ELECTRICITY meters (from the prop) with a line for each meter
          this.getMeterElectricity.map(meter => {
            promises.push(this.getElectricityChartData(meter, electricityColorIndex));
            electricityColorIndex++;
          });
        }

        if (!promises.length) {
          if (this.gas && this.electricity) {
            //get all electricity and gas meters in one chart with 2 lines
            promises.push(this.getGasChartData());
            promises.push(this.getElectricityChartData());

          } else if (this.gas) {
            //get all electricity meters in one chart with 1 line
            if (this.meters.length) {
              promises.push(this.getGasChartData(this.getMeterElectricity[0]));
            } else {
              promises.push(this.getGasChartData());
            }
          } else if (this.electricity) {
            //get all gas meters in one chart with 1 line
            if (this.meters.length) {
              promises.push(this.getElectricityChartData(this.getMeterGas[0]));
            } else {
              promises.push(this.getElectricityChartData());
            }
          }
        }

        Promise.all(promises).then((data) => {
          let datasets = [];
          data.forEach((dataObject) => {
            datasets.push(dataObject.dataset);
          });
          resolve({
            labels: data[0].labels,
            dates: data[0].dates,
            datasets: datasets,
          });
        });
      });
    },
    getElectricityChartData(meter = [], colorIndex) {
      return new Promise((resolve) => {
        this.$http.get('me/organisation/current/measurement/chart/electricity/' + this.unit, {
          params: {
            from: this.from,
            to: this.to,
            meters: 'id' in meter ? [meter.id] : [],
          },
        }).then((response) => {
          let labels = response.data.data.map(data => {
            return this.$moment(data.period, 'YYYY-MM-DD H:m').toDate();
          });
          let color = this.getColorByIndex(false/*not a gas type*/, colorIndex);
          let dates = response.data.meta;
          let dataset = {
            label: meter?.label || 'Elektriciteitsmeters',
            isElectricity: true,
            borderWidth: 1,
            borderColor: color,
            backgroundColor: this.convertHex(color, 30),
            pointBackgroundColor: color,
            pointRadius: 0,
            hitRadius: 3,
            pointHoverRadius: 3,
            data: response.data.data.map(data => {
              return {
                y: parseFloat(data.amount),
                t: this.$moment(data.period, 'YYYY-MM-DD H:m').toDate(),
              };
            }),
          };
          resolve({
            labels: labels,
            dataset: dataset,
            dates: dates,
          });
        });
      });

    },
    getGasChartData(meter = [], colorIndex) {
      return new Promise((resolve) => {
        this.$http.get('me/organisation/current/measurement/chart/gas/' + this.unit, {
          params: {
            from: this.from,
            to: this.to,
            meters: 'id' in meter ? [meter.id] : [],
          },
        }).then((response) => {
          let labels = response.data.data.map(data => {
            return this.$moment(data.period, 'YYYY-MM-DD H:m').toDate();
          });
          let dates = response.data.meta;
          let color = this.getColorByIndex(true/*is a gas type*/, colorIndex);
          let dataset =
              {
                label: meter?.label || 'Gasmeters',
                isElectricity: false,
                borderColor: color,
                backgroundColor: this.convertHex(color, 30),
                pointBackgroundColor: color,
                pointRadius: 0,
                borderWidth: 1,
                hitRadius: 3,
                pointHoverRadius: 3,
                data: response.data.data.map(data => {
                  return {
                    y: parseFloat(data.amount),
                    t: this.$moment(data.period, 'YYYY-MM-DD H:m').toDate(),
                  };
                }),
              };
          resolve({
            labels: labels,
            dataset: dataset,
            dates: dates,
          });
        });
      });

    },
  },
}
;
</script>

<style lang="scss" scoped>

</style>
