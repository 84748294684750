<template>
  <v-row>
    <v-col cols="6">
      <v-menu
          ref="menu"
          v-model="modals.startDate"
          :return-value.sync="modals.startDate"
          lazy
          full-width
          width="290px"
          :close-on-content-click="false"
      >
        <template #activator="{ on }">
          <v-text-field
              v-model="date.startDate"
              label="van"
              prepend-icon="fa-calendar-day"
              readonly
              v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
            v-model="selected"
            :min="this.date.start"
            :max="this.date.end"
            @input="saveStartDate"
            locale="nl-NL"
            first-day-of-week="1"
        ></v-date-picker>
      </v-menu>
    </v-col> &nbsp;
    <v-col cols="6">
      <v-menu
          ref="menu2"
          v-model="modals.endDate"
          :return-value.sync="modals.endDate"
          lazy
          full-width
          width="290px"
          :close-on-content-click="false"
      >
        <template #activator="{ on }">
          <v-text-field
              v-model="date.endDate"
              label="tot"
              prepend-icon="fa-calendar-day"
              readonly
              v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
            v-model="selected"
            :min="this.date.start"
            :max="this.date.end"
            @input="saveEndDate"
            locale="nl-NL"
            first-day-of-week="1"
        ></v-date-picker>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'UsagePeriodSelector',
  props: {
    'calculationDate': null,
    minDate: {
      required: false,
      type: String,
    },
    maxDate: {
      required: false,
      type: String,
    },
  },
  data() {
    return {
      date: {
        start: this.minDate || null,
        end: this.maxDate || null,
      },
      modals: {
        startDate: false,
        endDate: false,
      },
      selected: '',
    };
  },
  methods: {
    saveStartDate() {
      this.date.startDate = this.selected;
      this.modals.startDate = false;

      this.sendData();
    },
    saveEndDate() {
      this.date.endDate = this.selected;
      this.modals.endDate = false;

      this.sendData();
    },
    sendData() {
      if (this.date.endDate !== '' && this.date.startDate !== '' && new Date(this.date.startDate).getTime() < new Date(this.date.endDate).getTime()) {
        this.$emit('dataSelected', { 'data': this.date });
      }
    },
  },
};
</script>

<style scoped>

</style>
