<template>
  <div class="my-3">
    <v-tooltip left>
      <template v-slot:activator="{ on }">
        <v-row v-on="!!$slots.date && on" no-gutters>
          <v-col cols="auto" class="mr-3 pa-0">
            <v-icon color="primary" v-if="type === 'electricity'">far fa-bolt</v-icon>
            <v-icon color="secondary" v-else-if="type === 'resupply'">far fa-solar-panel</v-icon>
            <v-icon color="accent" v-else>far fa-burn</v-icon>
          </v-col>
          <v-col class="pa-0">
            <loading-placeholder :is-loading="isLoading">
              <span class="subtitle mr-3"><slot name="content"></slot></span>
              <span class="caption"><slot name="ean"></slot></span>
            </loading-placeholder>
          </v-col>
        </v-row>
      </template>
      <span><slot name="date"></slot></span>
    </v-tooltip>
  </div>
</template>

<script>
import LoadingPlaceholder from '@/components/LoadingPlaceholder.vue';

export default {
  name: 'UsageRecapItem',
  components: { LoadingPlaceholder },
  props: {
    type: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
    },
  },
};
</script>

<style scoped>

</style>
