<template>
  <v-container class="pa-0 pb-3 ma-2 black--text" v-if="summation !== null">

    <v-row class="ma-0">
      <v-col class="body-2 mb-0 pa-0 pl-2 font-weight-bold">{{ unit }} {{ previous_year }}</v-col>
      <v-col class="body-2 mb-0 pa-0 pl-2 font-weight-bold">{{ unit }} {{ current_year }}</v-col>
    </v-row>

    <v-row class="ma-0">
      <v-col class="body-1 mb-1 pa-0" cols="1">
        <v-tooltip bottom :disabled="previous_electricity_period == null">
          <template v-slot:activator="{ on }">
            <v-icon color="primary" v-on="on">fal fa-bolt</v-icon>
          </template>
          <span>{{ previous_electricity_period }}</span>
        </v-tooltip>
      </v-col>
      <v-col class="pa-0">
        <template v-if="summation.electricity.last_year.amount == null">
          nog geen data
        </template>
        <template v-else>
          {{ summation.electricity.last_year.amount | parseToNumber }} kWh
        </template>
      </v-col>

      <v-col class="body-1 mb-1 pa-0" cols="1">
        <v-tooltip bottom :disabled="current_electricity_period == null">
          <template v-slot:activator="{ on }">
            <v-icon color="primary" v-on="on">fal fa-bolt</v-icon>
          </template>
          <span>{{ current_electricity_period }}</span>
        </v-tooltip>
      </v-col>
      <v-col class="pa-0">
        <template v-if="summation.electricity.this_year.amount == null">
          nog geen data
        </template>
        <template v-else>
          {{ summation.electricity.this_year.amount | parseToNumber }} kWh
          <template v-if="summation.electricity.this_year.amount > summation.electricity.last_year.amount">
            <v-icon small color="red">fas fa-arrow-up</v-icon>
          </template>
          <template v-if="summation.electricity.this_year.amount < summation.electricity.last_year.amount">
            <v-icon small color="green">fas fa-arrow-down</v-icon>
          </template>
        </template>
      </v-col>
    </v-row>

    <v-row class="ma-0">
      <v-col class="body-1 mb-1 pa-0" cols="1">
        <v-tooltip bottom :disabled="previous_gas_period == null">
          <template v-slot:activator="{ on }">
            <v-icon color="accent" v-on="on">fal fa-burn</v-icon>
          </template>
          <span>{{ previous_gas_period }}</span>
        </v-tooltip>
      </v-col>
      <v-col class="pa-0">
        <template v-if="summation.gas.last_year.amount == null">
          nog geen data
        </template>
        <template v-else>
          {{ summation.gas.last_year.amount | parseToNumber }} m³
        </template>
      </v-col>

      <v-col class="body-1 mb-1 pa-0" cols="1">
        <v-tooltip bottom :disabled="current_gas_period == null">
          <template v-slot:activator="{ on }">
            <v-icon color="accent" v-on="on">fal fa-burn</v-icon>
          </template>
          <span>{{ current_gas_period }}</span>
        </v-tooltip>
      </v-col>
      <v-col class="pa-0">
        <template v-if="summation.gas.this_year.amount == null">
          nog geen data
        </template>
        <template v-else>
          {{ summation.gas.this_year.amount | parseToNumber }} m³
          <template v-if="summation.gas.this_year.amount > summation.gas.last_year.amount">
            <v-icon small color="red">fas fa-arrow-up</v-icon>
          </template>
          <template v-if="summation.gas.this_year.amount < summation.gas.last_year.amount">
            <v-icon small color="green">fas fa-arrow-down</v-icon>
          </template>
        </template>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>

export default {
  props: ['summation', 'previous_year', 'current_year', 'previous_electricity_period', 'current_electricity_period', 'previous_gas_period', 'current_gas_period', 'unit', 'unitName'],
  name: 'summation-unit',
  methods: {
    notEqual(first, second) {
      return first !== second;
    },
  },
};
</script>
