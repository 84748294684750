var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.summation !== null)?_c('v-container',{staticClass:"pa-0 pb-3 ma-2 black--text"},[_c('v-row',{staticClass:"ma-0"},[_c('v-col',{staticClass:"body-2 mb-0 pa-0 pl-2 font-weight-bold"},[_vm._v(_vm._s(_vm.unit)+" "+_vm._s(_vm.previous_year))]),_c('v-col',{staticClass:"body-2 mb-0 pa-0 pl-2 font-weight-bold"},[_vm._v(_vm._s(_vm.unit)+" "+_vm._s(_vm.current_year))])],1),_c('v-row',{staticClass:"ma-0"},[_c('v-col',{staticClass:"body-1 mb-1 pa-0",attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.previous_electricity_period == null},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"primary"}},on),[_vm._v("fal fa-bolt")])]}}],null,false,2493013261)},[_c('span',[_vm._v(_vm._s(_vm.previous_electricity_period))])])],1),_c('v-col',{staticClass:"pa-0"},[(_vm.summation.electricity.last_year.amount == null)?[_vm._v(" nog geen data ")]:[_vm._v(" "+_vm._s(_vm._f("parseToNumber")(_vm.summation.electricity.last_year.amount))+" kWh ")]],2),_c('v-col',{staticClass:"body-1 mb-1 pa-0",attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.current_electricity_period == null},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"primary"}},on),[_vm._v("fal fa-bolt")])]}}],null,false,2493013261)},[_c('span',[_vm._v(_vm._s(_vm.current_electricity_period))])])],1),_c('v-col',{staticClass:"pa-0"},[(_vm.summation.electricity.this_year.amount == null)?[_vm._v(" nog geen data ")]:[_vm._v(" "+_vm._s(_vm._f("parseToNumber")(_vm.summation.electricity.this_year.amount))+" kWh "),(_vm.summation.electricity.this_year.amount > _vm.summation.electricity.last_year.amount)?[_c('v-icon',{attrs:{"small":"","color":"red"}},[_vm._v("fas fa-arrow-up")])]:_vm._e(),(_vm.summation.electricity.this_year.amount < _vm.summation.electricity.last_year.amount)?[_c('v-icon',{attrs:{"small":"","color":"green"}},[_vm._v("fas fa-arrow-down")])]:_vm._e()]],2)],1),_c('v-row',{staticClass:"ma-0"},[_c('v-col',{staticClass:"body-1 mb-1 pa-0",attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.previous_gas_period == null},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"accent"}},on),[_vm._v("fal fa-burn")])]}}],null,false,2823433697)},[_c('span',[_vm._v(_vm._s(_vm.previous_gas_period))])])],1),_c('v-col',{staticClass:"pa-0"},[(_vm.summation.gas.last_year.amount == null)?[_vm._v(" nog geen data ")]:[_vm._v(" "+_vm._s(_vm._f("parseToNumber")(_vm.summation.gas.last_year.amount))+" m³ ")]],2),_c('v-col',{staticClass:"body-1 mb-1 pa-0",attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.current_gas_period == null},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"accent"}},on),[_vm._v("fal fa-burn")])]}}],null,false,2823433697)},[_c('span',[_vm._v(_vm._s(_vm.current_gas_period))])])],1),_c('v-col',{staticClass:"pa-0"},[(_vm.summation.gas.this_year.amount == null)?[_vm._v(" nog geen data ")]:[_vm._v(" "+_vm._s(_vm._f("parseToNumber")(_vm.summation.gas.this_year.amount))+" m³ "),(_vm.summation.gas.this_year.amount > _vm.summation.gas.last_year.amount)?[_c('v-icon',{attrs:{"small":"","color":"red"}},[_vm._v("fas fa-arrow-up")])]:_vm._e(),(_vm.summation.gas.this_year.amount < _vm.summation.gas.last_year.amount)?[_c('v-icon',{attrs:{"small":"","color":"green"}},[_vm._v("fas fa-arrow-down")])]:_vm._e()]],2)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }