<template>
  <v-row class="usage-recap-wrapper ma-0" v-if="electricityStatistics && gasStatistics">
    <v-col cols="4" class="pa-3 pl-0">
      <v-card class="white-card">
        <v-card-title>
          Verbruik
        </v-card-title>
        <v-card-text>
          <usage-recap-item type="electricity" :is-loading="isLoading">
            <template #content>{{ electricityStatistics.usage.amount | parseToNumber }} kWh</template>
          </usage-recap-item>
          <usage-recap-item type="resupply" :is-loading="isLoading">
            <template #content>{{ electricityStatistics.resupply.amount | parseToNumber }} kWh</template>
          </usage-recap-item>
          <usage-recap-item type="gas" :is-loading="isLoading">
            <template #content>{{ gasStatistics.usage | parseToNumber }} m3</template>
          </usage-recap-item>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="4" class="pa-3">
      <v-card class="white-card">
        <v-card-title>
          Kosten
        </v-card-title>
        <v-card-text>
          <usage-recap-item type="electricity" :is-loading="isLoading">
            <template #content>{{ electricityStatistics.usage.cost | currency }}</template>
          </usage-recap-item>
          <usage-recap-item type="resupply" :is-loading="isLoading">
            <template #content>{{ electricityStatistics.resupply.cost | currency }}</template>
          </usage-recap-item>
          <usage-recap-item type="gas" :is-loading="isLoading">
            <template #content>{{ gasStatistics.cost | currency }}</template>
          </usage-recap-item>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="4" class="pa-3 pe-0">
      <v-card class="white-card">
        <v-card-title>
          Hoogste piek
        </v-card-title>
        <v-card-text>
          <usage-recap-item type="electricity" :is-loading="isLoading">
            <template #content>{{ electricityStatistics.usage.peak.usage | parseToNumber }} kWh per kwartier</template>
            <template #ean>{{ eanAbbreviation(electricityStatistics.usage.peak.ean) }}</template>
            <template #date>{{ dateFormat(electricityStatistics.usage.peak.timestamp) }}</template>
          </usage-recap-item>
          <usage-recap-item type="resupply" :is-loading="isLoading">
            <template #content>{{ electricityStatistics.resupply.peak.usage | parseToNumber }} kWh per kwartier
            </template>
            <template #ean>{{ eanAbbreviation(electricityStatistics.resupply.peak.ean) }}</template>
            <template #date>{{ dateFormat(electricityStatistics.resupply.peak.timestamp) }}</template>
          </usage-recap-item>
          <usage-recap-item type="gas" :is-loading="isLoading">
            <template #content>{{ gasStatistics.peak.usage | parseToNumber }} m3 per uur</template>
            <template #ean>{{ eanAbbreviation(gasStatistics.peak.ean) }}</template>
            <template #date>{{ dateFormat(gasStatistics.peak.timestamp) }}</template>
          </usage-recap-item>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
  <v-row v-else style="height:220px">
    <v-col v-for="i in 3" :key="i">
      <VSkeletonLoader type="image"/>
    </v-col>
  </v-row>
</template>

<script>
import getStatistics from '../../api/measurement/getStatistics.js';
import UsageRecapItem from './UsageRecapItem.vue';

export default {
  name: 'UsageRecap',
  components: { UsageRecapItem },
  props: {
    from: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      required: true,
    },
    meters: {
      type: Array,
      default: () => [],
    },
    unit: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      gasStatistics: null,
      electricityStatistics: null,
      timeout: null,
      isLoading: false,
    };
  },
  watch: {
    from: 'fetchDataProvoker',
    to: 'fetchDataProvoker',
    meters: 'fetchDataProvoker',
    unit: 'fetchDataProvoker',
  },
  computed: {
    gasMeters() {
      return this.getMeterIds(1);
    },
    electricityMeters() {
      return this.getMeterIds(2);
    },
  },
  created() {
    this.fetchDataProvoker();
  },
  methods: {
    async fetchData() {
      this.isLoading = true;
      const [gasStatistics, electricityStatistics] = await Promise.all([
        getStatistics('gas', this.gasMeters, this.from, this.to),
        getStatistics('electricity', this.electricityMeters, this.from, this.to),
      ]);

      this.gasStatistics = gasStatistics;
      this.electricityStatistics = electricityStatistics;
      this.isLoading = false;
    },
    fetchDataProvoker() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.fetchData();
      }, 100);
    },
    getMeterIds(type = 1) {
      return this.meters.reduce((result, meter) => {
        if (meter.meter_type_id === type) {
          result.push(meter);
        }
        return result;
      }, []).map(meter => {
        return meter.id;
      });
    },
    eanAbbreviation(ean) {
      return ean ? (ean.length >= 6 ? '~' : '') + ean.substr(ean.length - 6) : '';
    },
    dateFormat(date) {
      if (!date) {
        return 'Geen datum bekend';
      }

      return this.$moment(date).format('D-M-Y H:mm');
    },
  },
};
</script>

<style scoped>

</style>
