<template>
  <v-card class="white-card" :class="{'summation-card': $vuetify.breakpoint.mdAndUp}">
    <v-card-title>
      <v-icon class="white--text mr-3">far fa-chart-bar</v-icon>
      <span class="title">Verbruik t.o.v. vorig jaar</span>
    </v-card-title>
    <v-card-text>
      <SummationUnit :summation="yearSummation"
                     :current_electricity_period="currentElectricityPeriod"
                     :previous_electricity_period="previousElectricityPeriod"
                     :current_gas_period="currentGasPeriod"
                     :previous_gas_period="previousGasPeriod"
                     :current_year="currentYear"
                     :previous_year="previousYear"
                     unit=""/>

      <SummationUnit :summation="monthSummation"
                     :current_year="currentYear"
                     :previous_year="previousYear"
                     :unit="monthLabel"/>

      <SummationUnit :summation="weekSummation"
                     :current_year="currentYear"
                     :previous_year="previousYear"
                     :unit="weekLabel"/>

    </v-card-text>
  </v-card>
</template>

<script>

import SummationUnit from './SummationUnit.vue';

export default {
  name: 'summation',
  components: { SummationUnit },
  watch: {
    calculationDate() {
      this.getSummations();
    },
  },
  props: ['calculation-date'],
  data() {
    return {
      currentElectricityPeriod: null,
      previousElectricityPeriod: null,

      currentGasPeriod: null,
      previousGasPeriod: null,

      currentYear: null,
      previousYear: null,

      monthLabel: null,
      weekLabel: null,

      yearSummation: null,
      monthSummation: null,
      weekSummation: null,
    };
  },
  created() {
    this.getSummations();
  },
  methods: {
    getSummation() {
      return new Promise((resolve) => {
        this.$http.get('me/organisation/current/measurement/summation').then((response) => {
          resolve(response.data.data);
        });
      });
    },
    getSummations() {

      this.getSummation().then((summation) => {

        this.yearSummation = {
          electricity: summation.electricity.year,
          gas: summation.gas.year,
        };

        const electricityPeriodThisYear = summation.electricity.year.this_year.period;
        this.currentElectricityPeriod = this.buildPeriodString(
            this.$moment(electricityPeriodThisYear.from),
            this.$moment(electricityPeriodThisYear.to),
        );

        const gasPeriodThisYear = summation.gas.year.this_year.period;
        this.currentGasPeriod = this.buildPeriodString(
            this.$moment(gasPeriodThisYear.from),
            this.$moment(gasPeriodThisYear.to),
        );

        this.currentYear = this.$moment().format('YYYY');
        const periodForYear = electricityPeriodThisYear.to ? electricityPeriodThisYear.to : gasPeriodThisYear.to;
        if (periodForYear) {
          this.currentYear = this.$moment(periodForYear).format('YYYY');
        }

        let electricityPeriodPreviousYear = summation.electricity.year.last_year.period;
        this.previousElectricityPeriod = this.buildPeriodString(
            this.$moment(electricityPeriodPreviousYear.from),
            this.$moment(electricityPeriodPreviousYear.to),
        );

        let gasPeriodPreviousYear = summation.gas.year.last_year.period;
        this.previousGasPeriod = this.buildPeriodString(
            this.$moment(gasPeriodPreviousYear.from),
            this.$moment(gasPeriodPreviousYear.to),
        );

        this.previousYear = this.$moment().subtract(1, 'year').format('YYYY');
        const periodForPreviousYear = electricityPeriodPreviousYear.to ? electricityPeriodPreviousYear.to : gasPeriodPreviousYear.to;
        if (periodForPreviousYear) {
          this.previousYear = this.$moment(periodForPreviousYear).format('YYYY');
        }

        this.monthSummation = {
          electricity: summation.electricity.month,
          gas: summation.gas.month,
        };
        if (summation.electricity.year.this_year.amount == null && this.monthSummation.electricity.this_year.amount == 0) {
          this.monthSummation.electricity.this_year.amount = null;
        }
        if (summation.gas.year.this_year.amount == null && this.monthSummation.gas.this_year.amount == 0) {
          this.monthSummation.gas.this_year.amount = null;
        }
        if (summation.electricity.year.last_year.amount == null && this.monthSummation.electricity.last_year.amount == 0) {
          this.monthSummation.electricity.last_year.amount = null;
        }
        if (summation.gas.year.last_year.amount == null && this.monthSummation.gas.last_year.amount == 0) {
          this.monthSummation.gas.last_year.amount = null;
        }
        this.monthLabel = this.$moment('2020-' + summation.electricity.month.month_number + '-01').format('MMMM')
            .toString();
        this.monthLabel = this.monthLabel.charAt(0).toUpperCase() + this.monthLabel.substring(1);

        this.weekSummation = {
          electricity: summation.electricity.week,
          gas: summation.gas.week,
        };
        if (summation.electricity.year.this_year.amount == null && this.weekSummation.electricity.this_year.amount == 0) {
          this.weekSummation.electricity.this_year.amount = null;
        }
        if (summation.gas.year.this_year.amount == null && this.weekSummation.gas.this_year.amount == 0) {
          this.weekSummation.gas.this_year.amount = null;
        }
        if (summation.electricity.year.last_year.amount == null && this.weekSummation.electricity.last_year.amount == 0) {
          this.weekSummation.electricity.last_year.amount = null;
        }
        if (summation.gas.year.last_year.amount == null && this.weekSummation.gas.last_year.amount == 0) {
          this.weekSummation.gas.last_year.amount = null;
        }

        this.weekLabel = 'Week ' + summation.electricity.week.week_number;
      });

    },

    buildPeriodString(from, to) {

      if (from == null) {
        return null;
      } else if (!from.isValid() || !to.isValid()) {
        return 'geen verbruiksdata voor dezelfde dagen dit jaar en vorig jaar';
      }

      return `${from.format('D-M-YYYY').toString()} t/m ${to.format('D-M-YYYY').toString()}`;

    },
  },
};
</script>

<style>
.summation-card {
  min-height: 100%;
}
</style>
