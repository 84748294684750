<template>
  <v-row class="page-usage">
    <v-col md="4" cols="12">
      <v-row class="ma-0">
        <v-col class="mb-4 pa-0" cols="12" :class="{'pr-3': $vuetify.breakpoint.mdAndUp}">
          <v-card class="white-card">
            <v-card-title>
              <v-icon class="white--text mr-3">fal fa-cog</v-icon>
              <span class="title">Instellingen</span>
            </v-card-title>
            <v-card-text>
              <MeterSelector v-model="meterSelection"
                              meter-selector-label="Selecteer hier individuele meters"
                              disable-null-option/>
              <usage-unit-selector v-model="chartType"></usage-unit-selector>
              <template v-if="chartType === 'period'">
                <usage-period-selector
                    @dataSelected="gettingUsagePeriodSelector"
                    :minDate="usageDates.start"
                    :maxDate="usageDates.end"
                ></usage-period-selector>
                <v-select
                    v-model="selectedUnityType"
                    :items="unitTypes"
                    item-text="name"
                    item-value="value"
                    label="Weergeven op"
                ></v-select>
              </template>
              <v-date-picker
                  v-show="chartType === 'day' || chartType === 'month' || chartType === 'week'"
                  :type="datePickerType"
                  @change="setDates()"
                  class="mt-4"
                  full-width
                  locale="nl-nl"
                  :min="usageDates.start"
                  :max="usageDates.end"
                  v-model="calculationDate"
                  first-day-of-week="1"></v-date-picker>
              <v-date-picker-years
                  v-if="chartType === 'year' && usageDates.start !== null && usageDates.end !== null "
                  ref="picker"
                  v-model="calculationYear"
                  @input="setDates()"
                  reactive
                  no-title
                  :min="usageDates.start"
                  :max="usageDates.end"
              ></v-date-picker-years>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col class="mb-4 pa-0" cols="12" :class="{'pr-3': $vuetify.breakpoint.mdAndUp}">
          <summation :calculation-date="calculationDate"></summation>
        </v-col>
      </v-row>
    </v-col>
    <v-col md="8" cols="12">
      <v-card class="white-card pb-3 ie-fix-flex-basis">
        <v-card-title>
          <v-icon class="white--text mr-3">fal fa-bolt</v-icon>
          <span class="title">Elektriciteitsverbruik</span>
        </v-card-title>
        <v-card-text class="pa-3">
          <v-row class="ma-0">
            <div class="loader-wrapper" v-if="loadingEnergy">
              <v-progress-circular
                  :size="50"
                  :width="4"
                  color="primary"
                  indeterminate
              ></v-progress-circular>
            </div>
          </v-row>
          <usage
              :from="from.format('YYYY-MM-DD HH:mm:ss')"
              :meters="meterSelection"
              :to="to.format('YYYY-MM-DD HH:mm:ss')"
              :unit="unit"
              @complete="loadingEnergy = false"
              @loading="loadingEnergy = true"
              @getDates="getDates"
              electricity
              v-show="!loadingEnergy"
              :key="from+to+meterSelection+unit"
          ></usage>
        </v-card-text>
      </v-card>

      <UsageRecap
          :from="from.format('YYYY-MM-DD HH:mm:ss')"
          :meters="meterSelection"
          :unit="unit"
          :to="to.format('YYYY-MM-DD HH:mm:ss')"/>

      <v-card class="white-card">
        <v-card-title>
          <v-icon class="white--text mr-3">fal fa-burn</v-icon>
          <span class="title">Gasverbruik</span>
        </v-card-title>
        <v-card-text class="pa-3">
          <v-row class="ma-0">
            <div class="loader-wrapper" v-if="loadingGas">
              <v-progress-circular
                  :size="50"
                  :width="4"
                  color="accent"
                  indeterminate
              ></v-progress-circular>
            </div>
          </v-row>
          <usage
              :from="from.format('YYYY-MM-DD HH:mm:ss')"
              :meters="meterSelection"
              :to="to.format('YYYY-MM-DD HH:mm:ss')"
              :unit="unit"
              @complete="loadingGas = false"
              @loading="loadingGas = true"
              @getDates="getDates"
              gas
              :key="from+to+meterSelection+unit"
              v-show="!loadingGas"
          ></usage>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

import Usage from '../components/charts/Usage.vue';
import MeterSelector from '../components/MeterSelector.vue';
import Summation from '../components/Summation/Summation.vue';
import UsagePeriodSelector from '../components/UsagePeriodSelector';
import UsageRecap from '../components/UsageRecap/UsageRecap.vue';
import UsageUnitSelector from '../components/UsageUnitSelector.vue';
import user from '../mixins/user.js';

export default {
  components: {
    UsageRecap,
    MeterSelector,
    UsagePeriodSelector,
    UsageUnitSelector,
    Summation,
    Usage,
  },
  props: [],
  mixins: [user],
  name: 'PageUsage',
  data() {
    return {
      meterSelection: [],
      from: '',
      to: '',
      chartType: 'week',
      calculationDate: null,
      calculationYear: '',
      calculationDates: null,
      loadingGas: true,
      loadingEnergy: true,
      usageDates: {
        start: null,
        end: null,
      },
      selectedUnityType: 2,
      unitTypes: [
        {
          name: 'Kwartier',
          type: 'minute',
          value: 0,
        },
        {
          name: 'Uren',
          type: 'hour',
          value: 1,
        },
        {
          name: 'Dagen',
          type: 'day',
          value: 2,
        },
        {
          name: 'Maanden',
          type: 'month',
          value: 3,
        },
      ],
    };
  },
  watch: {
    chartType() {
      this.setDates();
    },
    calculationDate() {
      if (typeof this.calculationDate === 'undefined') {
        this.resetUsageDates();
      }
    },
  },
  computed: {
    unit() {
      switch (this.chartType) {
        case 'year':
          return this.unitTypes[3].type;
        case 'period':
          return this.unitTypes[this.selectedUnityType].type;
        case 'month':
          return this.unitTypes[2].type;
        case 'week':
          return this.unitTypes[1].type;
        default:
        case 'day':
          return this.unitTypes[0].type;
      }
    },
    datePickerType() {
      switch (this.chartType) {
        case 'period':
          return 'date';
        case 'month':
          return 'month';
        case 'week':
          return 'date';
        default:
        case 'day':
          return 'date';
      }
    },
  },
  created() {
    this.resetUsageDates();
    this.calculationYear = this.$moment().format('YYYY');
    this.setDates();
  },
  methods: {
    gettingUsagePeriodSelector(data) {
      this.calculationDates = data.data;

      this.setDates();
    },
    setDates() {
      switch (this.chartType) {
        case 'year':
          this.from = this.$moment(this.calculationYear, 'YYYY-MM-DD').startOf('year');
          this.to = this.$moment(this.calculationYear, 'YYYY-MM-DD').startOf('year').add(1, 'year');
          break;
        case 'month':
          this.from = this.$moment(this.calculationDate, 'YYYY-MM-DD').startOf('month');
          this.to = this.$moment(this.calculationDate, 'YYYY-MM-DD').startOf('month').add(1, 'month');
          break;
        case 'week':
          this.from = this.$moment(this.calculationDate, 'YYYY-MM-DD').startOf('week');
          this.to = this.$moment(this.calculationDate, 'YYYY-MM-DD').startOf('week').add(7, 'days');
          break;
        case 'period':
          if (this.calculationDates !== null && 'startDate' in this.calculationDates) {
            this.from = this.$moment(this.calculationDates.startDate, 'YYYY-MM-DD').startOf('day');
            this.to = this.$moment(this.calculationDates.endDate, 'YYYY-MM-DD').startOf('day');

            this.resetUsageDates();
          }
          break;
        default:
        case 'day':
          this.from = this.$moment(this.calculationDate, 'YYYY-MM-DD').startOf('day');
          this.to = this.$moment(this.calculationDate, 'YYYY-MM-DD').startOf('day').add(1, 'day');
          break;
      }
    },
    getDates(dates) {
      if (this.usageDates.start === null || dates.start_date < this.usageDates.start) {
        this.usageDates.start = dates.start_date;
      }

      if (this.usageDates.end === null || dates.end_date > this.usageDates.end) {
        this.usageDates.end = dates.end_date;
      }
    },
    resetUsageDates() {
      this.calculationDate = this.$moment().subtract(7, 'days').startOf('week').format('YYYY-MM-DD');
    },
  },
};
</script>

<style lang="scss">

.loader-wrapper {
  min-height: 250px;
  height:     25vh;
  width:      100%;
  position:   relative;
}

.loader-wrapper > * {
  top:         50%;
  left:        50%;
  position:    absolute;
  margin-left: -25px;
  margin-top:  -25px;
}

.v-date-picker-years {
  max-height: 286px;
  height:     auto;
}

.v-date-picker-title__date > div {
  font-size: 2rem !important;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .ie-fix-flex-basis {
    flex-basis: auto !important;
  }
}

@supports (-webkit-overflow-scrolling: touch) {
  /* CSS specific to iOS devices */
  .page-usage canvas {
    height: 250px !important;
  }
}

@supports not (-webkit-overflow-scrolling: touch) {
  /* CSS for other than iOS devices */
  .page-usage canvas {
    min-height: 250px;
    height:     25vh !important;
  }
}
</style>
