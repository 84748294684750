<template>
  <div>
    <VSkeletonLoader
        v-if="loading"
        type="text"
        height="12px"
        style="margin-top: 5px"/>

    <slot v-else/>
  </div>
</template>

<script>
export default {
  name: 'LoadingPlaceholder',
  props: {
    isLoading: {
      type: Boolean,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  created() {
    this.loading = this.isLoading;
  },
  watch: {
    isLoading() {
      this.loading = this.isLoading;
    },
  },
};
</script>
